<template>
    <div class="account-content page-template-account">
        
        
         <content-loader  v-if="loaded==false"
            viewBox="0 0 520 230"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >

            <rect x="110" y="20" rx="5" ry="5" width="300" height="10" />
            <rect x="110" y="60" rx="5" ry="5" width="300" height="10" />
            <rect x="110" y="100" rx="5" ry="5" width="300" height="10" />
            <rect x="110" y="140" rx="5" ry="5" width="300" height="10" />
            <rect x="110" y="180" rx="5" ry="5" width="300" height="10" />

        </content-loader>
        <div class="popup-login" id="popup-login-account" v-if="displayLoginForm || displayRegisterForm">
            <div class="content">
                <div class="login-form" v-if='displayLoginForm'>
                    <h3>Connexion</h3>
                    <input type="text" id="login_account" v-model="username" placeholder="Adresse e-mail">
                    <input type="password" id="password_account" v-model="password" placeholder="Mot de passe">
                    <a class="forgot" @click="displayForgotPassword()">Mot de passe oublié ?</a>
                    <center><button id="login_button_account" @click="login_account()">Connexion</button></center>
                    <div>
                        <br />
                        <center>{{errorLogin}}</center>
                        
                    </div>
                    <br />
                    <center>Vous n'avez pas de compte ? <a  class="register_link" @click='displayRegister()'>Inscrivez-vous</a>.</center>
                </div>
                <div class="register-form" v-if='displayRegisterForm'>
                    <h3>Inscription</h3>
                    
                    <input type="text" id="name_register_account" v-model="register_name" placeholder="Prénom*">
                    <input type="text" id="surname_register_account" v-model="register_surname" placeholder="Nom*">
                    <input type="text" id="email_register_account" v-model="register_email" placeholder="Email*">
                    <input type="password" id="password_register_account" v-model="register_password" placeholder="Mot de passe*">
                    <input type="text" class="phone-input" id="phone_register_account" v-model="register_phone" placeholder="Téléphone">
                    <center><button id="register_button_account" @click="register_account()">Inscription</button></center>
                    <div>
                        <br />
                        <center>{{errorRegister}}</center>
                        
                    </div>
                </div>
            </div>
        </div>
        <div id="error">{{error}}{{erroralready}}</div>
        <div id="account-content" v-if="displayAccountContent && already==false">
            <div v-if="assigned==true">
                Le scan a bien été assigné à votre compte !
            </div>
            <div v-else class="login-form">
                Souhaitez-vous assigner le scan {{scan.name}} au compte utilisant comme adresse mail {{usermail}} ?
                <br /><br />
                <center><button id="register_button_account" @click="assignScan()">Récupérer le scan</button>
                <br /><br />
                Ou <a @click="logout()">se connecter avec un autre compte</a></center>
            </div>
        </div>
    </div>
    <Footer :displayModalMenu="displayModalMenu" />
</template>

<script>
import {Auth, APIRequest} from "@simb2s/senseye-sdk";
import google from 'google';
import emitter from 'tiny-emitter/instance'
import Stripe from 'stripe';
import Footer from '../components/layout/Footer.vue'
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'GetScan',

   data() {
    return {
        usermail:"",
        assigned:false,
        user:null,
        username:'',
        loaded:false,
        scan:null,
        stripe:null,
        register_name:'',
        register_surname:'',
        register_password:'',
        register_phone:'',
        register_email:'',
        email:'',
        password:'',
        errorRegister:'',
        errorLogin:'',
        error:'',
        erroralready:'',
        displayRegisterForm:false,
        displayLoginForm:false,
        already:false,
        displayAccountContent:false
    }
  },
  props:["displayModalMenu"],
  methods:{
    async logout()
	{
        await Auth.logout()
        document.location.reload(); 
	},
    displayForgotPassword()
    {
        emitter.emit('displayforgotpassword');
    },
    async reloadContents()
    {
        this.displayAccountContent=true
    },
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
   
     async assignScan()  {
        this.loaded=false
        let Parameters = this.scan;
        Parameters.user = {}
        Parameters.user.id=localStorage.id_user_senseye_auth
        
        await APIRequest.update('scans',this.scan.id,Parameters)
        this.loaded=true
        this.assigned=true

    },
    
	async logout()
	{
        await Auth.logout()
        document.location.reload(); 
	},

    async getScan() {

        this.scan = await APIRequest.detail('scans',this.$route.params.id)
        if(this.scan) {
            if(this.scan.user)
            {
                if(this.scan.user!=null) {
                    this.erroralready = 'Ce scan a déjà été assigné à un compte.'
                    this.already=true
                }
            }
        }
        else {
            this.erroralready = "Ce scan n'existe pas."
            this.already=true
        }
    },

   async getUser()
	{   
		let user=this.parseJwt(localStorage.token_senseye_auth);
		let user_data=await Auth.get_user(user.username)        
		this.error='';
        this.usermail=user_data.email
     
		localStorage.id_user_senseye_auth=user_data.id;
	},
	async loginUser()
	{
        this.loaded=false
        await emitter.emit('login',this.username,this.password)

       
	},
    async register_account(){
		this.loaded=false
        let user=await emitter.emit('register',this.register_email,this.register_password,this.register_name,this.register_phone,this.register_surname,this.register_email)
		if(user!=null)
        {
            
        }
        else
        {
            this.loaded=true
            this.errorRegister='Il y a une erreur dans votre email ou votre mot de passe. Ou un compte exste déjà avec cet email.';
        }
    },
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    async login_account(){
        await this.loginUser()
        //await this.getUser()
        await this.reloadContents()
    },
    async userLogin (user) {
        if(user!=null)
        {
            
            document.location.reload();
        }
        else
        {
            this.errorLogin='Vos identifiants sont incorrects.';
            this.loaded=true
        }

    },
    formatDate(date) {
        var monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
        ];
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    },
   
    displayRegister()
    {
        this.displayLoginForm=false
        this.displayRegisterForm=true
    },
    async userRegister (user) {
            
            if(user!=null) {
                this.user=user
                this.username=this.register_email
                this.password=this.register_password
                await this.loginUser();   
            }
            else {
            
                this.errorRegister='Il y a une erreur dans votre email ou votre mot de passe. Ou un compte exste déjà avec cet email.';
            }
           
            this.loaded=true

        }
	
  },
  updated()
  {
      
  },
  
  components: {
    Footer,
    ContentLoader
  },
  async mounted()
  {
       

        // emitter.on('cart-created', async function () {
       
        //    document.location.reload();

        // }, this);

      


    
    jQuery('.phone-input').on('input', function (event) { 
        this.value = this.value.replace(/[^0-9]/g, '');
    });
	this.loaded=false
    
	if(localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth)
	{
        await this.getScan()
        await this.getUser()
        this.loaded=true
        this.displayAccountContent=true
    }
    else
    {
        
        this.loaded=true
        this.displayLoginForm=true
    } 
  }
}
</script>
<style scoped>
.popup-login
        {
            animation: fadeanimation 0.4s ease;
        }
#account-content button {
    max-width:300px;
}
a,a.register_link
{
  color:#0000FF;
}
</style>